/* intro.css */
/* Basic Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--light-blue);
  color: var(--dark-text);
}

header .welcome-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem;
  background: var(--neutral-beige);
}

header .welcome-banner .greeting-img {
    max-height: 60vh;
}

header .welcome-banner h1 {
  color: var(--dark-blue);
}

header .welcome-banner img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

header .welcome-banner p {
  font-size: 1.5rem;
  color: var(--light-text);
}

