@import "https://db.onlinewebfonts.com/c/ca6f91c0cad2fe33614026d17117601d?family=Edwardian+Script+ITC+V1";
*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

a {
  color: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

body {
  color: #333;
  background-color: #f8f8f8;
  font-family: Arial, sans-serif;
}

header, footer {
  text-align: center;
  padding: 0;
}

main {
  text-align: center;
  padding: 1em;
}

body {
  background-color: var(--light-blue);
  color: var(--dark-text);
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

header .welcome-banner {
  text-align: center;
  background: var(--neutral-beige);
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  display: flex;
}

header .welcome-banner .greeting-img {
  max-height: 60vh;
}

header .welcome-banner h1 {
  color: var(--dark-blue);
}

header .welcome-banner img {
  border-radius: 10px;
  max-width: 100%;
  height: auto;
}

header .welcome-banner p {
  color: var(--light-text);
  font-size: 1.5rem;
}

:root {
  --light-blue: #e6f4f1;
  --dark-blue: #305a72;
  --soft-green: #6d916c;
  --dark-red: brown;
  --dark-red-hover: maroon;
  --neutral-beige: #fff8dc;
  --dark-text: #040230;
  --light-text: #555;
}

body {
  color: var(--dark-text);
  font-family: Nunito, sans-serif;
}

main {
  padding: 0;
}

.hidden {
  display: none;
}

h1 {
  font-family: Edwardian Script ITC V1, cursive;
  font-size: 3.5rem;
  font-weight: 400;
}

h2 {
  font-family: Luxurious Script, cursive;
  font-size: 3rem;
  font-weight: 400;
}

h3, h4, h5, h6 {
  font-family: Raleway, sans-serif;
  font-weight: 400;
}

.section-img {
  max-height: 200px;
  margin: auto;
}

section {
  text-align: left;
  background-color: var(--light-blue);
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 2rem;
  display: flex;
}

section > * {
  max-width: min(500px, 100%);
}

section h2 {
  text-align: center;
  color: var(--dark-blue);
  margin-bottom: 1rem;
}

section:nth-child(2n) {
  background-color: var(--neutral-beige);
}

@media (width >= 500px) {
  h1 {
    font-size: 4rem;
  }
}

@media (width >= 600px) {
  h1 {
    font-size: 5rem;
  }
}

/*# sourceMappingURL=index.742539db.css.map */
