/* main.css */
@import url("./reset.css");
@import url("./intro.css");
@import url(https://db.onlinewebfonts.com/c/ca6f91c0cad2fe33614026d17117601d?family=Edwardian+Script+ITC+V1); 

:root {
  --light-blue: #E6F4F1;
  --dark-blue: #305A72;
  --soft-green: #6d916c;
  --dark-red: #A52A2A;
  --dark-red-hover: #800000;
  --neutral-beige: #FFF8DC;
  --dark-text: #040230;
  --light-text: #555;
}


body {
  font-family: 'Nunito', sans-serif;
  color: var(--dark-text);
}

main {
    padding: 0;
}

.hidden {
  display: none;
}

h1 {
  font-family: "Edwardian Script ITC V1", cursive; 
  font-weight: 400;
  font-size: 3.5rem;
}
h2 {
  font-family: 'Luxurious Script', cursive;
  font-weight: 400;
  font-size: 3rem;
}
h3, h4, h5, h6 {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
}

.section-img {
    max-height: 200px;
    margin: auto;
}

section {
  margin: auto;
  text-align: left;
  padding: 2rem;
  background-color: var(--light-blue);

  display: flex;
  flex-direction: column;
  align-items: center;
}

section > * {
    max-width: min(500px, 100%);
}

section h2 {
  margin-bottom: 1rem;
  text-align: center;
  color: var(--dark-blue);
}

/* Style for every even section */
section:nth-child(even) {
  background-color: var(--neutral-beige); /* Default background color for sections */
}


section:nth-child(even) h2 {
}

@media(min-width: 500px) {
  h1 {
    font-size: 4rem;
  }
}

@media(min-width: 600px) {
  h1 {
    font-size: 5rem;
  }
}
