/* reset.css */
/* Josh's Custom CSS Reset */
*, *::before, *::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
input, button, textarea, select {
  font: inherit;
}

a {
    color: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
#root, #__next {
  isolation: isolate;
}

/* Your Custom Styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f8f8f8;
  color: #333;
}

header, footer {
  text-align: center;
  padding: 0;
}

main {
  padding: 1em;
  text-align: center;
}
